import '@popperjs/core';
import '@popperjs/core';
import 'bootstrap';

import Cookies from 'js-cookie';

import { COOKIES } from '@/shared/cookies';

import { setDeviceId } from '@/front/device';
import '@/front/resize';
import '@/front/tooltip';

document.addEventListener('DOMContentLoaded', () => {
  setDeviceId();

  window.addEventListener('scroll', () => {
    document.body.classList.toggle('scrolled', window.scrollY >= 50);
  });

  const planIntervalSwitch = document.getElementById('plan-interval-switch') as HTMLInputElement | undefined;

  const plansMonth = document.getElementById('plans-month') as HTMLDivElement | undefined;
  const plansYear = document.getElementById('plans-year') as HTMLDivElement | undefined;

  if (planIntervalSwitch && plansMonth && plansYear) {
    planIntervalSwitch.addEventListener('click', () => {
      plansMonth.classList.toggle('active', !planIntervalSwitch.checked);
      plansYear.classList.toggle('active', planIntervalSwitch.checked);
    });
  }

  const cookieConsentDiv = document.getElementById('cookie-consent');

  if (cookieConsentDiv) {
    const cookieConsent = Cookies.get(COOKIES.COOKIE_CONSENT.name) === 'true';

    if (!cookieConsent) {
      cookieConsentDiv.classList.remove('d-none');

      const cookieConsentButton = cookieConsentDiv.querySelector('.ok.btn') as HTMLButtonElement;

      cookieConsentButton.addEventListener('click', () => {
        Cookies.set(COOKIES.COOKIE_CONSENT.name, 'true');

        cookieConsentDiv.classList.add('d-none');
      });
    }
  }
});

const w = window as any;

w.validateRecaptcha = () => {
  if (w.grecaptcha) {
    const response = w.grecaptcha.getResponse();

    if (response.length === 0) {
      alert('Please click the checkbox to confirm you are not a robot');
      return false;
    } else {
      return true;
    }
  }

  return false;
};

w.signUpSubmit = (c: { test: boolean; fathomEventId: string }) => {
  if (w.validateRecaptcha()) {
    (document.getElementById('submit') as HTMLButtonElement).disabled = true;

    if (w.fathom) {
      if (!c.test) {
        w.fathom.trackGoal(c.fathomEventId, 0);
      } else {
        console.log('[TEST] Track Fathom Trial');
      }
    }

    if (w.gtag) {
      if (!c.test) {
        w.gtag('event', 'trial');
      } else {
        console.log('[TEST] Track Google Trial');
      }
    }

    if (w.lintrk) {
      if (!c.test) {
        w.lintrk('track', { conversion_id: 18872586 });
      } else {
        console.log('[TEST] Track LinkedIn Trial');
      }
    }

    if (c.test) {
      alert('[TEST] Tracking complete');
    }

    return true;
  }

  return false;
};
