import Cookies from 'js-cookie';
import { v4 } from 'uuid';

import { COOKIES } from '@/shared/cookies';

export const setDeviceId = () => {
  const deviceId = Cookies.get(COOKIES.DEVICE_ID.name);

  if (!deviceId) {
    Cookies.set(COOKIES.DEVICE_ID.name, v4());
  }
};
