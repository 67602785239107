export type CookieType =
  | 'COOKIE_CONSENT'
  | 'DEVICE_ID'
  | 'EXISTING_USER'
  | 'DASHBOARD_VIEW'
  | 'DEFAULT_DASHBOARD_VIEW'
  | 'USER_ID_FILTER';

export const COOKIES: Record<CookieType, { name: string; slidingExpirationDays: number; clearOnSignOut: boolean }> = {
  COOKIE_CONSENT: {
    name: 'pf_cookieConsent',
    slidingExpirationDays: 90,
    clearOnSignOut: false,
  },
  DEVICE_ID: {
    name: 'pf_deviceId',
    slidingExpirationDays: 90,
    clearOnSignOut: false,
  },
  EXISTING_USER: {
    name: 'pf_existingUser',
    slidingExpirationDays: 90,
    clearOnSignOut: false,
  },
  DASHBOARD_VIEW: {
    name: 'pf_dashboardView',
    slidingExpirationDays: 7,
    clearOnSignOut: false,
  },
  DEFAULT_DASHBOARD_VIEW: {
    name: 'pf_defaultDashboardView',
    slidingExpirationDays: 7,
    clearOnSignOut: false,
  },
  USER_ID_FILTER: {
    name: 'pf_userIdFilter',
    slidingExpirationDays: 7,
    clearOnSignOut: true,
  },
};
